<template>
  <div class="container">
    <img :src="avatar" alt="avatar" class="avatar">
    <span class="nickname">{{ nickname }}</span>
  </div> 
</template>

<script>
export default {
  data() {
    return {
      nickname: "",
      avatar: "",
    }
  },
  created() {
    let { avatar, nickname } = this.$route.query;
    this.avatar = avatar;
    this.nickname = nickname;
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.7857;

.container {
  @include row;
  width: 100vw; 
  height: 80.47vw;
  background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01881e98-e291-dc9f-41e2-adfc18eb382d?x-oss-process=style/origin") no-repeat center center / cover;

  .avatar {
    position: absolute;
    top: px2rem(18 * $multipleCount);
    left: px2rem(14 * $multipleCount);
    flex-shrink: 0;
    width: px2rem(48 * $multipleCount);
    height: px2rem(48 * $multipleCount);
    border-radius: 50%;
  }

  .nickname {
    @include ellipsis;
    position: absolute;
    top: px2rem(26 * $multipleCount);
    left: px2rem(68 * $multipleCount);
    right: px2rem(10 * $multipleCount);
    font-size: px2rem(28 * $multipleCount);
    line-height: px2rem(34 * $multipleCount);
    color: #fff;
    font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
  }
}
</style>
